/**
 =========================================================
 * Material Dashboard 2 PRO React - v2.2.0
 =========================================================

 * Product Page: https://www.creative-tim.com/product/material-dashboard-pro-react
 * Copyright 2023 Creative Tim (https://www.creative-tim.com)

 Coded by www.creative-tim.com

 =========================================================

 * The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
 */
import _ from "lodash";
import moment from "moment";
// @material-ui core components
import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";
import Autocomplete from "@mui/material/Autocomplete";
import Swal2 from "sweetalert2";

// Material Dashboard 2 PRO React components
import MDButton from "components/MDButton";
import MDBox from "components/MDBox";
import MDDatePicker from "components/MDDatePicker";
import MDTypography from "components/MDTypography";

// Settings page components
import FormField from "views/examples/account/components/FormField";

// Data
import selectData from "views/examples/account/settings/components/BasicInfo/data/selectData";
import React, {useCallback} from "react";
import {usePlacesWidget} from "react-google-autocomplete";
import {API} from "api";
import {FormattedMessage, useIntl} from "react-intl";
import Switch from "@mui/material/Switch";

export function capitalizeFirstLetter(string) {
    return string?.charAt(0).toUpperCase() + string?.slice(1);
}

const api = new API()

function BasicInfo({form, setFormField, fetchUser}) {

    const intl = useIntl()
    const placeHandler = useCallback((place) => {
        setFormField('location', place.formatted_address)
        setFormField('locationData', {
            ...place,
            geometry: {...place.geometry, locationArray: [place.geometry.location.lng(), place.geometry.location.lat()]}
        })
    }, [form, setFormField])
    const {ref} = usePlacesWidget({
        apiKey: 'AIzaSyCcb4EBiv3DHMlzvVF_mcLaoN5JuGV4bS4',
        options: {
            types: ['address'],
            componentRestrictions: {country: "lt"},
        },
        onPlaceSelected: placeHandler
    })


    const handleSave = useCallback(async () => {
        await api.post(`users/${form.uuid}`, _.pick(form, ['programURL', 'cancelRule', 'foodUrl','languages','name', 'contactEmail', 'surname', 'gender', 'avatar', 'phone', 'location', 'locationData', 'birthDate', 'coachData.instagramURL', 'coachData.fbURL', 'coachData.foodURL', 'coachData.programURL']))
        await fetchUser()
        Swal2.fire('Saved!', 'Your profile is updated', 'success')
    }, [form]);

    return (
        <Card id="basic-info" sx={{overflow: "visible"}}>
            <MDBox p={3}>
                <MDTypography variant="h5"><FormattedMessage id={"forms.basic"}/></MDTypography>
            </MDBox>
            <MDBox component="form" pb={3} px={3}>
                <Grid container spacing={3}>
                    <Grid item xs={12}>
                        <FormField label={intl.formatMessage({id: "forms.basic.name"})} placeholder="Enter Your Name"
                                   inputProps={{autoComplete: 'new-password', value: form.name, onChange: v => setFormField('name', v)}}/>
                    </Grid>
                    <Grid item xs={12}>
                        <FormField label={intl.formatMessage({id: "forms.basic.surname"})} placeholder="Enter Your Surname"
                                   inputProps={{autoComplete: 'new-password', value: form.surname, onChange: v => setFormField('surname', v)}}/>
                    </Grid>
                    <Grid item xs={12}>
                        <Grid container spacing={3}>
                            <Grid item xs={12} sm={4}>
                                <Autocomplete
                                    defaultValue={selectData.gender[intl.locale[0]]}
                                    options={selectData.gender[intl.locale]}
                                    onChange={(_e, v) => setFormField('gender', v)}
                                    value={form.gender}
                                    renderInput={(params) => {
                                        return (
                                        <FormField {...params} label={intl.formatMessage({id: "forms.basic.gender"})} InputLabelProps={{shrink: true}}/>
                                    )}}
                                />
                            </Grid>
                            <Grid item xs={12} sm={8}>
                                <MDDatePicker
                                    onChange={([v]) => setFormField('birthDate', moment(v || undefined).format('YYYY-MM-DD'))}
                                    value={form.birthDate}
                                    options={{dateFormat: 'Y-m-d', maxDate: moment().format('YYYY-MM-DD')}}
                                    input={{value: form.birthDate, placeholder: intl.formatMessage({id: "forms.basic.bd"}), fullWidth: true}}/>
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <FormField
                            label={intl.formatMessage({id: "forms.basic.email"})}
                            placeholder="example@email.com" disabled={true}
                            inputProps={{autoComplete: 'new-password', value: form.contactEmail, onChange: v => setFormField('contactEmail', v)}}
                        />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <Autocomplete
                            multiple={true}
                            options={selectData.languages}
                            onChange={(_e, v) => setFormField('languages', v)}
                            value={form.languages}
                            getOptionKey={option=>selectData.languageCodes.find(c=>c.name===option).code}
                            getOptionLabel={(option) => capitalizeFirstLetter(intl.formatDisplayName(selectData.languageCodes.find(c=>c.name===option).code, {type:'language'}))}
                            renderInput={(params) => (
                                <FormField {...params} label={intl.formatMessage({id: "common.languages"})}  inputProps={{
                                    ...params.inputProps,
                                    autoComplete: 'new-password', // disable autocomplete and autofill
                                }} InputLabelProps={{shrink: true}}/>
                            )}
                        />
                    </Grid>
                    {/*<Grid item xs={12} sm={6}>
                        <FormField
                            label="confirmation email"
                            placeholder="example@email.com"
                            inputProps={{value: form.email2, onChange: v => setFormField('email2', v)}}
                        />
                    </Grid>*/}


                    {form.isBusiness && <Grid item xs={12} sm={6}>
                        <FormField inputProps={{ref, defaultValue: form.location}} label={intl.formatMessage({id: "forms.basic.location"})}
                                   placeholder="Sydney, A"/>
                    </Grid>}

                    {form.isBusiness && <Grid item xs={12} sm={6}>
                        <FormField inputProps={{autoComplete: 'new-password',defaultValue: form.coachData.instagramURL, onChange: v => setFormField('coachData.instagramURL', v)}} label={'Instagram'} />
                    </Grid>}
                    {form.isBusiness && <Grid item xs={12} sm={6}>
                        <FormField inputProps={{autoComplete: 'new-password',defaultValue: form.coachData.fbURL, onChange: v => setFormField('coachData.fbURL', v)}} label={'Facebook'} />
                    </Grid>}

                    <Grid item xs={12} sm={6}>
                        <FormField
                            label={intl.formatMessage({id: "forms.basic.phone"})}
                            placeholder="+40 735 631 620"
                            inputProps={{autoComplete: 'new-password',value: form.phone, onChange: v => setFormField('phone', v)}}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <small>
                            <div style={{
                                display: 'flex',
                                flexDirection: 'row',
                                alignItems: 'center'
                            }}>
                                <Switch
                                    size={'medium'}
                                    id={'cancelRule'}
                                    checked={form.cancelRule}
                                    onChange={(v) => setFormField('cancelRule', v.target.checked)}
                                />
                                <span>{intl.formatMessage({id: "common.24h"})}</span>
                            </div>
                            <sup>{intl.formatMessage({id: "common.24h.comment"})}</sup>
                        </small>
                    </Grid>
                    <MDBox ml="auto" mt={3}>
                        <MDButton variant="gradient" color="info" size="small" onClick={handleSave}>
                            <FormattedMessage id={"forms.basic.save"}/>
                        </MDButton>
                    </MDBox>
                </Grid>
            </MDBox>
        </Card>
    );
}

export default BasicInfo;
