/**
 =========================================================
 * Material Dashboard 2 PRO React - v2.2.0
 =========================================================

 * Product Page: https://www.creative-tim.com/product/material-dashboard-pro-react
 * Copyright 2023 Creative Tim (https://www.creative-tim.com)

 Coded by www.creative-tim.com

 =========================================================

 * The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
 */

// prop-types is a library for typechecking of props.
import React, {useRef, useEffect} from "react";

import dayGridPlugin from "@fullcalendar/daygrid";
import timeGridPlugin from "../../../lib/timegrid";
import interactionPlugin from "@fullcalendar/interaction";
import scrollgridPlugin from "@fullcalendar/scrollgrid";
import listPlugin from "@fullcalendar/list";
import momentTimezonePlugin from "@fullcalendar/moment-timezone";
// @mui material components
import Card from "@mui/material/Card";

// Material Dashboard 2 PRO React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";

// Custom styles for Calendar
import CalendarRoot from "./CalendarRoot";

// Material Dashboard 2 PRO React context
import {useMaterialUIController} from "context";

import enLocale from '@fullcalendar/core/locales/en-gb';
import ltLocale from '@fullcalendar/core/locales/lt';
import {useLocale} from "../../../useLocale";
import MDAlert from "../../../components/MDAlert";
import {Info} from "@mui/icons-material";
import {FormattedMessage, useIntl} from "react-intl";
import {useAuth} from "../../../useAuth";
import {FullCalendar} from "../../../components/FullCalendar";

function Calendar({header, cardHeight, passRef, children, noCard, noAlert=false, showFilter=false, ...rest}) {
    const [controller] = useMaterialUIController();
    const {locale} = useLocale();
    const intl = useIntl()
    const {user} = useAuth();
    const {darkMode} = controller;
    const calendarRef = useRef(null)
    useEffect(() => {
        calendarRef.current && passRef(calendarRef.current);
        if(calendarRef.current) {
            setTimeout(()=> {
                const alert1 = document.getElementById('alert1');
                if (alert1 && user.isBusiness &&   alert1.children &&   alert1.children.length>0) {
                    alert1.children[0].style.opacity=1;
                    const chunkButton = document.querySelector('.fc-toolbar-chunk>.fc-alert1-button');
                    if (chunkButton && user.isBusiness) {
                        chunkButton.replaceWith(alert1);
                    }
                } else if(alert1 && alert1.children && alert1.children.length>0) {
                    alert1.children[0].style.display='none';
                }
            }, 200)
        }
    }, [calendarRef, passRef])
    const isProfilePage = document.location.href.indexOf('coach-profile')!==-1;

    return (
        !noCard ? (<Card style={{height: '100%', marginTop: noCard?100:10}} className={'px-0 px-sm-1 px-md-2 pb-4'}>
            <MDBox
                borderRadius="lg"
                shadow="md"
                height={60}
                top={-30}
                bgColor={'#8330BC'}
                position="relative"
                zIndex={1}
            >
                <MDBox pt={header.title || header.date ? 2 : 0} textAlign={'center'} px={2} lineHeight={1}>
                    {header.title ? (
                        <MDTypography color={'white'} variant="h6" fontWeight="medium" textTransform="capitalize">
                            {header.title}
                        </MDTypography>
                    ) : null}
                </MDBox>
            </MDBox>
            {children ? (
                <MDBox className={'calendar-children-box'} p={3} mb={5} borderBottom={1}>
                    {children}
                </MDBox>
            ) : null}
            <CalendarRoot ownerState={{darkMode}}>
                {noCard && <MDBox sx={{
                    height: {xs: 120, sm: 50, md: 0}
                }}></MDBox>}
                {!noAlert && <MDBox id={'alert1'} style={{zIndex: 9,}}>
                <MDAlert id={'alert11'}
                    sx={{
                        zIndex: 99999999,
                        position: {xs: 'absolute', sm: 'absolute', xl: 'static'},
                        left: {xs: '8px', lg: showFilter?340:40, xl: 'auto'},
                        width: {xs:'calc(100% - 16px)', lg: `calc(100% - ${showFilter?360:40}px)`, xl: 'auto'},
                        top:{xs: showFilter?40:120, lg: showFilter?105:120, xl: 'auto',}
                    }}
                    style={{
                         opacity: 0,
                         //position: 'absolute',
                         padding:4,
                         fontWeight: 400,
                         minWidth: '50%',
                     }}
                     dismissible={true}
                     color={'info'}
                >
                    <div style={{paddingRight:10}}><Info fontSize={'large'}/></div>
                    <MDBox sx={{
                        fontSize: {
                            xs:'0.7rem',
                            lg: '0.75rem',
                            xxxl: '1rem'
                        }
                    }} style={{color:'white'}}><FormattedMessage id={"common.schedules.alert"}/></MDBox>
                </MDAlert></MDBox>}
                <FullCalendar
                    {...rest}
                    buttonText={{
                        next: intl.formatMessage({id: "calendar.nextweek"}),
                        prev: intl.formatMessage({id: "calendar.prevweek"}),
                        listWeek: intl.formatMessage({id: "bookings.list"}),
                        listMonth: intl.formatMessage({id: "bookings.list"}),
                        listYear: intl.formatMessage({id: "bookings.list"}),
                        timeGridWeek: intl.formatMessage({id: "bookings.grid"}),
                        today: intl.formatMessage({id: "bookings.thisweek"})
                    }}
                    customButtons={{
                        'alert1': {
                            text: ''//intl.formatMessage({id: "common.schedules.alert"})
                        }
                    }}
                    headerToolbar={{
                        start: 'title',
                        end: rest.headerToolbar.end?rest.headerToolbar.end:'today prev,next',
                        center: (user.isBusiness && !isProfilePage)?'alert1':''}}
                    locales={[ enLocale, {...ltLocale, noEventsText: 'Rezervacijų nerasta'} ]}
                    locale={locale}
                    schedulerLicenseKey={'GPL-My-Project-Is-Open-Source'}
                    plugins={[dayGridPlugin, timeGridPlugin, interactionPlugin, momentTimezonePlugin, scrollgridPlugin, listPlugin]}
                    firstDay={1}
                    ref={calendarRef}
                />
            </CalendarRoot>
        </Card>) : (<CalendarRoot ownerState={{darkMode}}>
            <FullCalendar
                {...rest}
                locales={[ enLocale, {...ltLocale, noEventsText: 'Rezervacijų nerasta'} ]}
                locale={locale}
                buttonText={{
                    next: intl.formatMessage({id: "calendar.nextweek"}),
                    prev: intl.formatMessage({id: "calendar.prevweek"}),
                    listWeek: intl.formatMessage({id: "bookings.list"}),
                    listMonth: intl.formatMessage({id: "bookings.list"}),
                    listYear: intl.formatMessage({id: "bookings.list"}),
                    timeGridWeek: intl.formatMessage({id: "bookings.grid"}),
                    today: intl.formatMessage({id: "bookings.thisweek"})
                }}
                schedulerLicenseKey={'GPL-My-Project-Is-Open-Source'}
                plugins={[dayGridPlugin, timeGridPlugin, interactionPlugin, momentTimezonePlugin, scrollgridPlugin, listPlugin]}
                firstDay={1}
                ref={calendarRef}
            />
        </CalendarRoot>)
    );
}

export default Calendar;
