import ExamplesNavbar from "../../../components/Navbars/ExamplesNavbar";
import Card from "@mui/material/Card";
import MDBox from "../../../components/MDBox";
import MDTypography from "../../../components/MDTypography";
import {FormattedMessage, useIntl} from "react-intl";
import CalendarRoot from "../Calendar/CalendarRoot";
import enLocale from "@fullcalendar/core/locales/en-gb";
import ltLocale from "@fullcalendar/core/locales/lt";
import dayGridPlugin from "@fullcalendar/daygrid";
import timeGridPlugin from "@fullcalendar/timegrid";
import interactionPlugin from "@fullcalendar/interaction";
import momentTimezonePlugin from "@fullcalendar/moment-timezone";
import scrollgridPlugin from "@fullcalendar/scrollgrid";
import listPlugin from "@fullcalendar/list";
import React, {useCallback, useEffect, useMemo, useRef, useState} from "react";
import {useAuth} from "../../../useAuth";
import Tooltip from "@mui/material/Tooltip";
import _, {cloneDeep} from "lodash";
import moment from "moment";
import {API} from "../../../api";
import {AvailabilityEditor} from "./AvailabilityEditor";
import {Info, Save, Warning, WarningAmber} from "@mui/icons-material";
import MDButton from "../../../components/MDButton";
import Alert from "@mui/material/Alert";
import {Paper} from "@mui/material";
import MDAlert from "../../../components/MDAlert";
import {FullCalendar} from "../../../components/FullCalendar";

const api = new API()

const days = ["MON", "TUE", "WED", "THU", "FRI", "SAT", "SUN"];
let initialSchedules = [];
let initialEvents = [];
//const currentDate= {current: null};

export const CoachScheduleEditor = () => {
    const [tempEvents, setTempEvents] = React.useState([]);
    const [schedules, setSchedules] = React.useState([]);
    const [loading, setLoading] = useState(false);
    const [expanded, setExpanded] = useState(false);
    const editorRef = useRef(null);
    const {user} = useAuth();
    let calendarRef = null;



    const intl = useIntl()

    const header = {title: intl.formatMessage({id: "schedule.header", defaultMessage:'Edit your availability'})};

    const getRef = (ref) => {
        calendarRef = {current: ref};
    }

    const fetchSchedules = async () => {
        setLoading(true)
        const data = await api.get('schedules');
        initialSchedules = _.sortBy(cloneDeep(data), 'createdAt');
        setSchedules(data);
        setLoading(false)
    }

    const pickFields = (s)=>_.pick(s, ['createdAt','startTime', 'endTime', 'activity', 'price', 'location', 'weekDay', 'end'])

    const unsavedChanges = useMemo(()=> {
        //console.log('unsavedChanges tempEvents', JSON.stringify(_.sortBy(tempEvents, 'createdAt').map(pickFields)))
        //console.log('unsavedChanges initialSchedules', JSON.stringify(_.sortBy(initialSchedules, 'createdAt').map(pickFields)))
        //console.log('unsavedChanges schedules', JSON.stringify(_.sortBy(schedules, 'createdAt').map(pickFields)))
        //console.log('unsavedChanges', JSON.stringify(_.sortBy(initialSchedules, 'createdAt').map(pickFields))===JSON.stringify(_.sortBy(schedules, 'createdAt').map(pickFields)))
        let match = JSON.stringify(
            _.sortBy(initialSchedules, 'createdAt').map(pickFields)
            ) ===
            JSON.stringify(
                _.sortBy(tempEvents||schedules, 'createdAt').map(pickFields)
            );
        if(match) {
            match = schedules.length === tempEvents.length;
        }
        //console.log('unsavedChanges', match, schedules.length, tempEvents.length)
        return (match || !initialSchedules)?
            "":
            <Alert
                style={{width: '33%', marginLeft:'auto', marginRight: 'auto', marginTop: 12}}
                icon={<WarningAmber color={'warning'}/>}
                color={'warning'}
                action={ <MDButton
                    variant="contained"
                    color="success"
                    size={'small'}
                    startIcon={<Save/>}
                    style={{}}
                    onClick={()=>editorRef.handleSave(schedules)}
                >
                    {intl.formatMessage({id: 'schedule.save'})}
                </MDButton>}
            >
                   <FormattedMessage id={'weekly.unsaved'} />

            </Alert>
    }, [initialSchedules, schedules, tempEvents])

    useEffect(()=>{
        fetchSchedules()
    }, [])

    const handleChangeSlot = useCallback((changeInfo) => {
        const eventsToEdit = cloneDeep(tempEvents);
        let { startTime, endTime, extendedProps, start, end} = cloneDeep(changeInfo.event);
        const { uuid, day } = extendedProps;
        // Find the event to edit using eventId
        let eventToEdit = eventsToEdit.find(ev => ev.eventId === extendedProps.eventId);
        if(!startTime) {
            startTime = moment(start).format("HH:mm")
            endTime = moment(end).format("HH:mm")
        }
        if (eventToEdit) {
            // Update the event only if the IDs match
            eventToEdit = {
                uuid,
                day,
                id: uuid,
                end: undefined,
                start: undefined,
                endTime,
                startTime,
                startDate: moment(startTime, 'HH:mm').format('YYYY-MM-DDTHH:mm:ss')
            };

            // Replace the updated event in the events array
            eventsToEdit[eventsToEdit.findIndex(ev => ev.eventId === extendedProps.eventId)] = eventToEdit;

            // Update state with the modified events array
            console.log(editorRef.updateSlot?.(eventToEdit))
            //setTempEvents(eventsToEdit);
        }
    }, [tempEvents, editorRef]);
    const renderSlot = ({event}) => {
        return (
            <Tooltip followCursor title={
                <MDBox className={'p-1 text-danger'}>
                    <MDTypography color={'inherit'} component={'div'} style={{lineHeight: 1, whiteSpace: 'pre-wrap'}}
                                  variant={'overline'}>
                        {intl.formatMessage({id: 'weekly.preview.tooltip'})}

                    </MDTypography>

                </MDBox>
            }>
                <MDBox className={'mb-n1'}>
                    <MDBox className={'label-box-new p-1 text-danger'} onClick={()=>{
                        setExpanded(true);
                        let counter = 0;
                        const interval1 = setInterval(()=> {
                            if(document.getElementById(`row-day-${event.extendedProps.weekDay}`)) {
                                clearInterval(interval1)
                                editorRef?.setDaysOpened([event.extendedProps.weekDay])
                                const interval = setInterval(()=> {
                                    console.log('auto-expand', document.getElementById(`slot_${event.extendedProps.uuid}`), event.extendedProps.uuid, event.extendedProps.weekDay, counter)
                                    if(document.getElementById(`slot_${event.extendedProps.uuid}`)) {
                                        clearInterval(interval)
                                        document.getElementById(`slot_${event.extendedProps.uuid}`)?.classList.add('slot-selected');
                                        setTimeout(()=>document.getElementById(`slot_${event.extendedProps.uuid}`)?.scrollIntoView({
                                            alignToTop: true,
                                            block: 'center',
                                            behavior: 'smooth'
                                        }), 600);
                                    }
                                    if(counter>100){
                                        clearInterval(interval)
                                    }
                                    counter++;
                                },50)
                            }
                        }, 10)

                    }}>

                        <MDTypography color={'inherit'} component={'div'} style={{lineHeight: 1, fontWeight: 600}}
                                      variant={'overline'}>{moment(event.start).format("HH:mm")}<span
                            className={'d-none d-lg-inline'}> - {moment(event.end).format("HH:mm")}</span></MDTypography>

                        <MDTypography color={'inherit'} component={'div'} style={{lineHeight: 1}}
                                      variant={'overline'}>{_.find(user.dicts?.sports, {key: event.extendedProps.activity})?.name[intl.locale]}</MDTypography>
                        {event.extendedProps.location && <MDTypography color={'inherit'} component={'div'}
                                                                       style={{

                                                                       }}
                                                                       className={'event-card-location'}
                                                                       variant={'caption'}><FormattedMessage id={'schedule.location'}/>: {_.find(user.coachData?.locations, {id: event.extendedProps.location})?.name}</MDTypography>}
                        {/*event.extendedProps.comment &&  <div className={'event-card-comment'} dangerouslySetInnerHTML={{__html: event.extendedProps.comment }}></div>*/}

                    </MDBox>
                </MDBox>
            </Tooltip>
        )
    }
    useEffect(() => {
        if(calendarRef.current) {
            setTimeout(()=> {
                const alert1 = document.getElementById('alert1');
                if (alert1 && user.isBusiness &&   alert1.children &&   alert1.children.length>0) {
                    alert1.children[0].style.opacity=1;
                    const chunkButton = document.querySelector('.fc-toolbar-chunk>.fc-alert1-button');
                    if (chunkButton && user.isBusiness) {
                        chunkButton.replaceWith(alert1);
                    }
                } else if(alert1 && alert1.children && alert1.children.length>0) {
                    alert1.children[0].style.display='none';
                }
            }, 400)
        }
    }, [calendarRef])



    return (
        <>
            <ExamplesNavbar/>
            <div style={{height: '100%', marginTop: 140, minWidth: 410, minHeight: 17*60}} className={'px-0 px-sm-1 px-md-3 px-xxl-5'}>
                <div  className={"my-5 px-0 px-lg-2 px-xxl-5 "}  style={{height: '100%', minHeight: 17*60}}>
                    <Card style={{height: '100%', marginTop: 100}} className={'px-1 px-sm-2 px-md-3 pb-4'}>
                        <MDBox
                            borderRadius="lg"
                            shadow="md"
                            height={60}
                            top={-30}
                            bgColor={'#8330BC'}
                            position="relative"
                            zIndex={1}
                        >
                            <MDBox pt={header.title || header.date ? 2 : 0} textAlign={'center'} px={2} lineHeight={1}>
                                {header.title ? (
                                    <MDTypography color={'white'} variant="h6" fontWeight="medium"
                                                  textTransform="capitalize">
                                        {header.title}
                                    </MDTypography>
                                ) : null}
                            </MDBox>
                        </MDBox>

                        <AvailabilityEditor setExpanded={setExpanded} expanded={expanded} fetchSchedules={fetchSchedules} schedules={schedules} setSchedules={setSchedules} editorRef={editorRef} tempEvents={tempEvents}
                                            setTempEvents={setTempEvents}/>
                        <div style={{height: 60}}>{(schedules.length>0 && tempEvents.length>0)?unsavedChanges:''}</div>
                        <hr/>
                        {/*<pre>{JSON.stringify(tempEvents, undefined, 2)}</pre>*/}
                        {/*<pre style={{fontSize: 10, lineHeight: 1.2}}>{JSON.stringify(tempEvents, undefined, 2)}</pre>*/}

                        <MDBox id={'alert1'}
                        style={{zIndex: 9}}
                        >
                            <MDAlert id={'alert11'}
                                     sx={{
                                         position: {xs: 'absolute', sm: 'absolute', lg: 'relative', xl:'relative', xxl:'relative', xxxl:'relative'},
                                         left: {xs: '4px', sm: '40px', lg: 'auto'},
                                         width: {xs: 'calc(100% - 8px)', sm: 'calc(100% - 80px)', lg: 'auto'},


                                         top:{xs: 38, lg: 'auto',}
                                     }}
                                     style={{
                                         zIndex: 999,
                                         opacity: 0,
                                         //position: 'absolute',
                                         padding:4,
                                         fontWeight: 400,
                                         justifyContent: 'center',
                                         margin:'auto'
                                     }}
                                     dismissible={true}
                                     color={'info'}
                            >
                                <div style={{paddingRight:10}}><Info fontSize={'large'}/></div>
                                <MDBox sx={{
                                    fontSize: {
                                        xs:'0.7rem',
                                        lg: '0.75rem',
                                        xxxl: '1rem'
                                    }
                                }} style={{color:'white'}}><FormattedMessage id={'common.editCTA'}/></MDBox>
                            </MDAlert></MDBox>
                        <Paper style={{minWidth: 800}}>

                        <CalendarRoot>
                            <FullCalendar
                                allDaySlot={false}
                                timeZone={'Europe/Vilnius'}
                                height={'100%'}
                                events={tempEvents}
                                eventMaxStack={3}
                                editable={true}
                                snapDuration={'00:05:00'}
                                slotDuration={'00:30:00'}
                                initialView={'timeGridWeek'}
                                slotMinTime={'06:00'}
                                slotMaxTime={'23:00'}
                                locales={[enLocale, {...ltLocale, noEventsText: 'Rezervacijų nerasta'}]}
                                locale={intl.locale}
                                eventChange={handleChangeSlot}
                                schedulerLicenseKey={'GPL-My-Project-Is-Open-Source'}
                                plugins={[dayGridPlugin, timeGridPlugin, interactionPlugin, momentTimezonePlugin, scrollgridPlugin, listPlugin]}
                                firstDay={1}
                                buttonText={{
                                    listWeek: intl.formatMessage({id: "bookings.list"}),
                                    listMonth: intl.formatMessage({id: "bookings.list"}),
                                    listYear: intl.formatMessage({id: "bookings.list"}),
                                    timeGridWeek: intl.formatMessage({id: "bookings.grid"}),
                                    today: intl.formatMessage({id: "bookings.thisweek"})
                                }}
                                customButtons={{
                                    alert1:{
                                        text: 'Šioje skiltyje galite redaguoti savo darbo grafiką paspaudus ant langelių grafike arba kairėje esantį mygtuką “redaguoti dienotvarkę”.'
                                    },
                                    modal1: {
                                        click(ev, element) {
                                            setExpanded(true)
                                        },
                                        text: intl.formatMessage({
                                            id:"schedule.edit",
                                            defaultMessage:'Edit your availability'
                                        })
                                    }
                                }}
                                headerToolbar={{
                                    start: 'title',
                                    center: 'modal1 alert1',
                                    end: 'today prev,next'
                                }}
                                ref={getRef}
                                slotLabelFormat={(date) => {
                                    return `${date.start.hour.toString().padStart(2, '0')}:00　`
                                }}
                                eventContent={renderSlot}
                            />
                        </CalendarRoot>
                        </Paper>
                    </Card>
                </div>
            </div>
        </>
    )
}
