/**
=========================================================
* Material Dashboard 2 PRO React - v2.2.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-pro-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import React, {memo, useCallback, useEffect, useMemo, useRef, useState} from "react";
import _ from "lodash";

// @mui material components
import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";

// Material Dashboard 2 PRO React components
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import MDTypography from "components/MDTypography";


import PulseLoader from "react-spinners/PulseLoader";


import {API} from "api";
import {FormattedMessage, useIntl} from "react-intl";
import {useAuth} from "../../../../../../useAuth";

import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import CreatableSelect from "react-select/creatable";
import Select from "react-select";
import {v4} from "uuid";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
import IconButton from "@mui/material/IconButton";
import EditLocationIcon from '@mui/icons-material/EditLocation';
import {MDDialog} from "../../../../../../components/Dialog";
import {APIProvider, ControlPosition, Map, MapControl, Marker, useMap, useMapsLibrary} from '@vis.gl/react-google-maps';
import FormField from "../../../components/FormField";
import {useLocale} from "../../../../../../useLocale";

const api = new API()


const MapHandler = ({place}) => {
    const map = useMap();

    useEffect(() => {
        if (!map || !place) return;

        if (place.lat) {
            map.setCenter(place);
        }
    }, [map, place]);

    return null;
};

export const PlaceAutocompleteClassic = ({onPlaceSelect}) => {
    const [placeAutocomplete, setPlaceAutocomplete] =
    useState(null);
    const intl = useIntl();
    const inputRef = useRef(null);
    const places = useMapsLibrary('places');

    useEffect(() => {
        if (!places || !inputRef.current) return;

        const options = {


            componentRestrictions: {country: "lt"},
        };

        setPlaceAutocomplete(new places.Autocomplete(inputRef.current, options));
    }, [places]);

    useEffect(() => {
        if (!placeAutocomplete) return;

        placeAutocomplete.addListener('place_changed', () => {
            onPlaceSelect(placeAutocomplete.getPlace());
        });
    }, [onPlaceSelect, placeAutocomplete]);

    return (
        <div className="autocomplete-container position-absolute">
            <input placeholder={intl.formatMessage({id:'forms.map.search'})} className={'form-control'} ref={inputRef} />
        </div>
    );
};

export const CustomMapControl = ({
                                     controlPosition,
                                     onPlaceSelect
                                 }) => {

    return (
        <MapControl position={controlPosition}>
            <div className="autocomplete-control">
                <PlaceAutocompleteClassic onPlaceSelect={onPlaceSelect}  />
            </div>
        </MapControl>
    );
};


export const MapWithAMarker = ({coordinates, marker, onClick}) => (
    <APIProvider apiKey={'AIzaSyCcb4EBiv3DHMlzvVF_mcLaoN5JuGV4bS4'}>
        <Map
            defaultCenter={coordinates || marker || {lat: 54.695, lng: 25.27}}
            defaultZoom={12.75}
            onClick={onClick}
        >
            {marker && <Marker position={marker} />}
        </Map>
        <CustomMapControl
            controlPosition={ControlPosition.TOP}
            onPlaceSelect={onClick}
        />

        <MapHandler place={marker} />

    </APIProvider>
);

const ModalContent =({id, coordinates, name: defaultName, cbk}) => {
    const intl = useIntl()
    const [marker, setMarker] = useState(coordinates || null)
    const [name, setName] = useState(defaultName)

    useEffect(() => {
        cbk(id, marker, name)
    }, [name]);

    return (<div>
        <Grid container>

            <Grid item xs={12}>
                <FormField label={intl.formatMessage({id: "forms.book.location"})} placeholder=""
                           inputProps={{autoComplete: 'new-password', value: name, onChange: e => setName(e.target.value)}}/>
            </Grid>
        <Grid item xs={12}>
            <div style={{height:400, width: '100%'}}>
            <MapWithAMarker
                marker={marker}
                coordinates={coordinates}
                onClick={(event) => {
                    let coords={}
                    if(event.detail) {
                        coords = {lat: event.detail?.latLng.lat, lng: event.detail.latLng.lng}
                    } else if (event.geometry) {
                        coords = {lat: event.geometry.location.lat(), lng: event.geometry.location.lng()}
                    }
                    if(coords.lat || name) {
                        setMarker(coords)
                        console.log(id, coords, name)
                        cbk(id, coords, name)
                    }
                }}
            />
            </div>
        </Grid>
        </Grid>
    </div>)
}

function Dictionaries({form, setFormField, updateUser, fetchUser}) {
    const {user} = useAuth();
    const intl = useIntl();
    const {locale} = useLocale();
    const [loading, setLoading] = useState(false);
    const [open, setOpen] = useState(false);
    const [pointCoords, setPointCoords] = useState(null);
    const [modalContent, setModalContent] = React.useState(null);

    const [options, setOptions] = useState({
        activities: user.dicts?.sports?.map(a => ({
            label: a.name[locale],
            value: a.key
        })) || [],
        locations: user.dicts?.uniqueLocations?.map(a => ({
            label: a.name,
            value: a.id
        })) || [],
    })

    useEffect(()=>{
        setOptions({
            activities: user.dicts?.sports?.map(a => ({
                label: a.name[locale],
                value: a.key
            })) || [],
            locations: user.dicts?.uniqueLocations?.map(a => ({
                label: a.name,
                value: a.id
            })) || [],
        })
    }, [locale])


  const handleSetLocation =  useCallback(async (id, coordinates, name) => {

      setModalContent(<ModalContent  containerElement={<div style={{ height: `400px` }} />}
                                     mapElement={<div style={{ height: `100%` }} />} id={id} name={name} coordinates={coordinates} cbk={(id, newCoords, name)=>{
          setPointCoords({id, newCoords, name})
      }}/>)
      setOpen(true)
  }, [])

  const handleCreate =  useCallback(async (id, key, value) => {

      if(!id) {
          id = user.coachData[key].find(a=>a.name === value)?.id
      }
      setLoading(true);
      await api.post('users/add-option', {key, item: {id, name: value}})
      await fetchUser()
      setLoading(false);
      setOptions( {
          activities: user.dicts?.sports?.map(a => ({
              label: a.name[locale],
              value: a.key
          })) || [],
          locations: user.dicts?.uniqueLocations?.map(a => ({
              label: a.name,
              value: a.id
          })) || [],
      });
    }, [])

  const handleDelete =  useCallback(async (id, key, value) => {

      setLoading(true);
      await api.post('users/remove-option', {key, item: {id, name: value}})
      user.coachData[key] = []
      await fetchUser()
      setLoading(false);
      setOptions( {
          activities: user.dicts?.sports?.map(a => ({
              label: a.name[locale],
              value: a.key
          })) || [],
          locations: user.dicts?.uniqueLocations?.map(a => ({
              label: a.name,
              value: a.id
          })) || [],
      });
    }, [])

  const toggleLoading =  useCallback((b) => {
      setLoading(b)
  }, [setLoading])


  return (
    <Card id="dictionaries-card">
        <MDDialog
            size={'lg'}
            title={intl.formatMessage({id: 'forms.map.title'})}
            open={open}
            content={modalContent}
            onClose={()=>setOpen(false)}
            onSave={async ()=>{
                setLoading(true)
                await api.post('users/update-option', {key: 'locations', id: pointCoords.id, data: {name: pointCoords.name||undefined, coordinates: pointCoords.newCoords}})
                setOpen(false)
                await fetchUser()
                setLoading(false)
            }}
        />
        {loading && <Card className={'loader-card'} sx={({ palette}) => ({ borderRadius: '12px 12px 0 0', zIndex: 99999999999, textAlign: 'center', height: 58, width: 'calc(100% + 2px)', paddingY: '14px', backgroundColor: palette.white.main, position: 'absolute', top: 1, left: -1})}>
            <PulseLoader style={{width: 80, margin: 'auto', left: 'calc(50% - 40px)', top: 'calc(50% - 12px)',  position: 'absolute'}} color={'#9180ff'} size={18}/>
        </Card>}
      <MDBox p={2}>


        <Grid container spacing={3} >
            <Grid item xs={12} lg={6} sx={{paddingRight: '16px', borderRight: {xs: '', lg: 'solid 1px #f4f4f4'}}}>

                <MDTypography variant={'h5'} color={'dark'}><FormattedMessage id={'forms.sports.title'}/></MDTypography>
                <hr/>
                <Select
                    placeholder={intl.formatMessage({id: 'forms.sports.add'})}
                    isClearable
                    onChange={(v) => handleCreate(v.value, 'activities', v.label)}
                    options={options.activities}
                    value={null}
                />
                <hr/>
                <Table>
                    <TableHead>
                        <TableRow>
                            <TableCell>{intl.formatMessage({id: 'forms.sports.name'})}</TableCell>
                            <TableCell></TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>

                        {(user.coachData.activities || []).map((row) => (
                            <TableRow
                                key={row.name}
                                sx={{'&:last-child td, &:last-child th': {border: 0}}}
                            >
                                <TableCell component="th" scope="row">
                                    {user?.dicts?.sports.find(d => d.key === row.id)?.name[intl.locale]}
                                </TableCell>

                                <TableCell align="right">
                                    <IconButton
                                        size="medium"
                                        onClick={() => handleDelete(row.id, 'activities', row.name)}
                                    >
                                        <DeleteForeverIcon color={'error'}/>
                                    </IconButton>
                                </TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </Grid>


            <Grid item xs={12} lg={6}>


                <MDTypography variant={'h5'}
                              color={'dark'}>{intl.formatMessage({id: 'forms.map.locations'})}</MDTypography>

                <hr/>
                <CreatableSelect
                    placeholder={intl.formatMessage({id: 'forms.map.add'})}
                    noOptionsMessage={() => 'Type new item and press Enter to create'}
                    isClearable
                    onChange={(v) => handleCreate(v.value, 'locations', v.label)}
                    onCreateOption={(inputValue) => handleCreate(v4(), 'locations', inputValue)}
                    options={options.locations}
                    value={null}
                />
                <hr/>
                <Table width={'100%'}>
                    <TableHead width={'100%'}>
                        <TableRow>
                            <TableCell>{intl.formatMessage({id: 'forms.sports.name'})}</TableCell>
                            <TableCell>{intl.formatMessage({id: 'forms.map.location'})}</TableCell>
                            <TableCell></TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {(user.coachData.locations || []).map((row) => (
                            <TableRow
                                key={row.name}
                                sx={{'&:last-child td, &:last-child th': {border: 0}}}
                            >
                                <TableCell width={'50%'} className={'cursor-pointer'}
                                           onClick={() => handleSetLocation(row.id, row.coordinates, row.name)}>
                                    {row.name}
                                </TableCell>
                                <TableCell>

                                    <IconButton
                                        size="medium"
                                        color={'violet'}
                                        onClick={() => handleSetLocation(row.id, row.coordinates, row.name)}
                                    >
                                        <EditLocationIcon/>
                                    </IconButton>
                                </TableCell>

                                <TableCell align="right">
                                    <IconButton
                                        size="medium"
                                        onClick={() => handleDelete(row.id, 'locations', row.name)}
                                    >
                                        <DeleteForeverIcon color={'error'}/>
                                    </IconButton>
                                </TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </Grid>
        </Grid>
      </MDBox>
    </Card>
  );
}

export default Dictionaries;
