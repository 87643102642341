/**
 =========================================================
 * Material Dashboard 2 PRO React - v2.2.0
 =========================================================

 * Product Page: https://www.creative-tim.com/product/material-dashboard-pro-react
 * Copyright 2023 Creative Tim (https://www.creative-tim.com)

 Coded by www.creative-tim.com

 =========================================================

 * The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
 */

import { useState, useCallback } from "react";

// react-router-dom components
import { Link } from "react-router-dom";

// @mui material components
import Card from "@mui/material/Card";

// Material Dashboard 2 PRO React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDInput from "components/MDInput";
import MDButton from "components/MDButton";

import VerificationInput from "react-verification-input";

// Authentication layout components
import CoverLayout from "./components/CoverLayout";
import {useAuth} from "../../../useAuth";
import { Row, Col } from "reactstrap";
// Images
import bgImage from "assets/images/26727090.jpg";

import {
    LoginSocialFacebook,
    LoginSocialInstagram,
} from 'reactjs-social-login'

import IconFacebook from "@mui/icons-material/Facebook";
import IconInstagram from "@mui/icons-material/Instagram";
import Swal from "sweetalert2";
import {FormattedMessage, useIntl} from "react-intl";
import startCase from "lodash/startCase";


function Cover() {
    const intl = useIntl()
    const [sent, setSent] = useState(false);
    const [code, setCode] = useState(null);
    const [name, setName] = useState('');
    const [surname, setSurname] = useState('');
    const [email, setEmail] = useState('');
    const [phone, setPhone] = useState('');
    //const [showResend, setShowResend] = useState(false);
    const [password, setPassword] = useState('');
    const [passwordRepeat, setPasswordRepeat] = useState('');

    const reqFields = {name, surname, email, phone, password, passwordRepeat}

    const auth = useAuth();

    const [provider, setProvider] = useState('')
    const [profile, setProfile] = useState()

    const urlParams = new URLSearchParams(window.location.search);
    const redirectCB = useCallback((user) => {
        document.location.href = (urlParams.get('from') || (user.isBusiness ? '/business/calendar' : '/search'))
    }, [auth.user])

    const onLoginStart = useCallback(() => {
        console.log(profile, provider)
    }, [profile, provider])

    const sendVerificationCode = useCallback(async () => {
        const reqFieldsKeys = Object.keys(reqFields);
        const missingFields = Object.values(reqFields).reduce((arr, v, currentIndex) => {
            if(!v) {
                arr.push(startCase(reqFieldsKeys[currentIndex]))
            }
            return arr;
        }, []);

        if(missingFields.length > 0) {
            Swal.fire({
                title: '',
                text: `${intl.formatMessage({id: 'common.missing.fields'})}: ${missingFields.map(f=>intl.formatMessage({id: 'common.fields.'+f.toLowerCase()})).join(', ')}`,
                icon: 'warning',
                confirmButtonText: 'OK'
            })
        } else {
            if(password!==passwordRepeat) {
                Swal.fire({
                    title: '',
                    text: `Passwords does not match`,
                    icon: 'warning',
                    confirmButtonText: 'OK'
                })
            } else {
                if(!/[0-9A-Za-z]{6,20}/.test(password)) {
                    Swal.fire({
                        title: '',
                        text: `Password must be at least 6 characters long`,
                        icon: 'warning',
                        confirmButtonText: 'OK'
                    })
                } else {
                    const exists = await auth.checkUser(email, phone)
                    if (exists) {
                        Swal.fire({
                            title: 'Error!',
                            text: 'User already exists, use sign in',
                            icon: 'error',
                            confirmButtonText: 'OK'
                        })
                    } else {
                        await auth.sendCode({email, phone, name, surname, password, isBusiness: true}, (res) => {
                            if (res) {
                                setSent(true);
                                //setTimeout(() => setShowResend(true), 10000)
                            }
                        })
                    }
                }
            }
        }

    }, [reqFields])

    return (
        <CoverLayout image={bgImage}>
            <Card>
                <MDBox
                    variant="gradient"
                    bgColor="info"
                    borderRadius="lg"
                    coloredShadow="success"
                    mx={2}
                    mt={-3}
                    p={3}
                    mb={1}
                    textAlign="center"
                >
                    <MDTypography variant="h4" fontWeight="medium" color="white" mt={1}>
                        Join us today
                    </MDTypography>
                    <MDTypography display="block" variant="button" color="white" my={1} mb={2}>
                        Enter your name, email or phone to register or use one of the social services below
                    </MDTypography>

                </MDBox>
                <MDBox pt={4} pb={3} px={3}>
                    <MDBox component="form" role="form" className={'form-wrapper'}>
                        <MDBox mb={2}>
                            <MDInput type="text" label={intl.formatMessage({id: "common.name"})} variant="standard"
                                     name={'name'} fullWidth value={name} onChange={e => setName(e.target.value)}/>
                        </MDBox>
                        <MDBox mb={2}>
                            <MDInput type="text" label={intl.formatMessage({id: "common.surname"})} variant="standard"
                                     name={'surname'} fullWidth value={surname}
                                     onChange={e => setSurname(e.target.value)}/>
                        </MDBox>
                        <MDBox mb={2}>
                            <MDInput type="email" label={intl.formatMessage({id: "common.email"})} name={'email'}
                                     variant="standard" fullWidth value={email}
                                     onChange={e => setEmail(e.target.value.toLowerCase())}/>
                        </MDBox>
                        <MDBox mb={2}>
                            <MDInput type="text" label={intl.formatMessage({id: "common.phone"})} name={'phone'}
                                     variant="standard" fullWidth value={phone}
                                     onChange={e => setPhone(e.target.value.toLowerCase())}/>
                        </MDBox>

                        <fieldset className={'form-fieldset'}>
                            <legend>Password must be at least 6 characters long</legend>
                            <MDBox mb={2}>
                                <MDInput disabled={sent}
                                         fullWidth
                                         label={intl.formatMessage({id: "common.password"})}
                                         variant="standard"
                                         value={password}
                                         name={'pw1'}
                                         onChange={e => setPassword(e.target.value)}
                                         inputProps={{type: "password", autoComplete: "new-password"}}
                                />
                            </MDBox>
                            <MDBox mb={2}>
                                <MDInput disabled={sent}
                                         fullWidth
                                         label="Repeat Password"
                                         variant="standard"
                                         value={passwordRepeat}
                                         name={'pw2'}
                                         onChange={e => setPasswordRepeat(e.target.value)}
                                         inputProps={{type: "password", autoComplete: "new-password"}}
                                />
                            </MDBox>
                        </fieldset>

                        <MDBox mt={4} mb={1}>

                            {sent && phone && <MDBox mt={4} mb={1}>
                                <MDTypography variant={'h6'} mb={2}>Please verify
                                    your {phone ? 'phone' : 'email'}:</MDTypography>
                                <MDBox mb={3}>
                                    {phone && sent && <div style={{
                                        width: '100%',
                                        display: 'flex',
                                        justifyContent: 'center',
                                        overflowX: 'auto',
                                        overflowY: 'hidden'
                                    }}>
                                        <VerificationInput validChars={'0-9'} onChange={setCode}/>
                                    </div>}
                                </MDBox>
                                <MDButton variant="gradient" color="success" fullWidth onClick={async () => {
                                    if (code) {
                                        setSent(true);
                                        auth.signup(email, phone, name, surname, code, true, redirectCB)
                                    }
                                }}>
                                    Verify
                                </MDButton>
                            </MDBox>}
                            {!sent && <MDButton variant="gradient"
                                                color={(name && ((email && /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/.test(email)) && /^[\+\ 0-9()-]{3,15}$/.test(phone))) ? "info" : "light"}
                                                fullWidth onClick={async () => {
                                return sendVerificationCode();
                            }}>
                                <FormattedMessage id={"common.signup"}/>
                            </MDButton>}
                        </MDBox>
                        <MDBox mt={3} mb={1} textAlign="center">
                            <MDTypography variant="button" color="text">
                                <FormattedMessage id={"common.already.registered"}/>
                                {" "}
                                <MDTypography
                                    component={Link}
                                    to={`/business/login?from=${urlParams.get('from') || '/search'}`}
                                    variant="button"
                                    color="info"
                                    fontWeight="medium"
                                    textGradient
                                >
                                    <FormattedMessage id={"common.signin"}/>
                                </MDTypography>
                            </MDTypography>
                        </MDBox>
                    </MDBox>
                </MDBox>
            </Card>
        </CoverLayout>
    );
}

export default Cover;
